@import "../Settings/colours.scss";

.App {
    background: $true-black;
    border-radius: 50px;
    color: $true-white;
    height: 944px;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 562px;
}

.display {
    display: flex;
    flex-direction: column-reverse;
    font-size: 130px;
    font-weight: 300;
    height: 250px;
    margin-bottom: 20px;
    text-align: right;
}

.keypad {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
}
